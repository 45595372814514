@import "../../../assets/scss/variables";

.TabContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: $border-radius-big;
  box-shadow: $medium-box-shadow;
  background: $color-primary-5;
  min-width: 600px;
  width: 600px;

  .tabHeader {
    display: flex;
    height: 50px;
    background: $color-primary-4;
    align-items: center;

    .label {
      display: flex;
      flex: 1 1 100%;
      align-items: center;
      padding: 0 $padding-big;
      font-size: $font-size-large;
      font-weight: 600;
      letter-spacing: $letter-spacing;
      color: $color-primary-5;
      background: $color-primary-4;
    }

    .title {
      @extend .label;
      border: none;
      outline: none;
      min-width: 0px;
      height: 50px;
      max-height: 50px;
      font-family: $font-family-source-sans-pro;
    }

    .separator {
      width: 2px;
      min-width: 2px;
      background: $color-primary-3;
      margin: 0 $margin-small;
      height: 50%;
    }
  }

  .tabContent {
    display: flex;
    min-height: 200px;
    border-bottom-left-radius: $border-radius-big;
    border-bottom-right-radius: $border-radius-big;
  }

  @media (max-width: $window-size-mobile) {
    max-width: 100%;
    min-width: 100%;
  }
}
