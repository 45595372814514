@import "../../assets/scss/variables";

.SceneDashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 100%;
  position: relative;

  .navigation {
    display: flex;
    width: 100%;
    justify-content: flex-start;
  }

  .listButtons {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    flex-direction: column;
    top: 50px;
    left: 0;
    width: fit-content;

    > label {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      > span {
        padding-left: $padding-small;
        font-weight: bold;
        font-family: $font-family-visby-bold;
      }
    }

    .shadowButton {
      box-shadow: $small-box-shadow;
    }

    .buttons {
      display: none;
      flex-direction: column;
      opacity: 0;
      transition: opacity 0.3s ease;
      width: fit-content;

      .button {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        width: fit-content;
        margin-top: $margin-medium;

        > span {
          padding-left: $padding-small;
          font-weight: bold;
          font-family: $font-family-visby-bold;
        }

        .shadowButton {
          box-shadow: $small-box-shadow;
        }
      }

      &__expanded {
        display: flex;
        opacity: 1;
      }
    }
  }

  .emptySpace {
    height: 32px;
    transition: height 0.2s ease;
    &__shrink {
      height: 0px;
    }
  }

  .draggableSceneContainer {
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    justify-content: center;
    padding: 0 $padding-small;
    max-width: 100%;
  }

  @media (max-width: $window-size-mobile) {
    flex-direction: row;
    flex-wrap: wrap;

    .draggableSceneContainer {
      margin-top: 25px;
    }

    .navigation {
      position: relative;
      left: 0;
      width: fit-content;
      margin-right: $margin-medium;
    }

    .listButtons {
      position: relative;
      top: 0;
      flex-direction: row;

      > label {
        display: none;
      }

      .buttons {
        flex-direction: row;
        display: flex;
        opacity: 1;

        .button {
          margin-top: 0;
          margin-right: $margin-medium;

          > span {
            display: none;
          }
        }
      }
    }
  }
}
