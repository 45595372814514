@import "../../assets/scss/variables";

.StoryDashboard {
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: $margin-big;
  }

  .stories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .story {
      display: flex;
      justify-content: space-between;
      flex: 0 0 calc(50% - #{$padding-small});
      margin-bottom: $margin-medium;
      height: 50px;
      border-radius: $border-radius-small;
      border: 3px solid $color-primary-3;
      padding: $padding-small $padding-medium;
      font-weight: 600;
      align-items: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background: $color-primary-3;
        color: $color-white;
      }

      svg {
        width: 24px;
        height: 24px;

        &:hover {
          color: $msg-color-error;
        }
      }
    }
  }

  @media (max-width: $window-size-mobile) {
    .stories {
      flex-direction: column;

      .story {
        flex: 0 0 100%;
      }
    }
  }
}
