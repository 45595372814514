@import "../../../assets/scss/variables.scss";

.Button {
  border: none;
  background: $color-primary-4;
  color: white;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  padding: $padding-medium;
  user-select: none;

  width: fit-content;
  &__stretch {
    width: auto;
    flex: 1 1 100%;
  }

  .icon {
    display: flex;
    svg {
      width: 12px;
      height: 12px;
    }

    &__padding {
      margin-right: $margin-small;
    }
  }

  &:hover {
    cursor: pointer;
    background: $color-primary-3;
  }

  &:active {
    background: $color-primary-2;
  }

  &:disabled {
    background: rgba($color-primary-3, 0.2);
    cursor: not-allowed;
  }

  &__round {
    width: $button-size;
    min-width: $button-size;
    height: $button-size;

    border-radius: 50%;
  }

  &__roundedCorners {
    border-radius: $border-radius-small;
  }

  &__big {
    height: $button-size;
  }

  &__transparent {
    background: transparent;
    border: none;
    color: $color-primary-1;

    &:hover {
      background: transparent;
      color: $color-primary-2;
    }
  }
}
