body {
  margin: 0;
  background: whitesmoke;
}

* {
  box-sizing: border-box;
  font-family: "VisbyRegular", sans-serif, "Roboto Mono", monospace;
}

@font-face {
  font-family: "VisbyRegular";
  src: url("./assets/fonts/VisbyCF-Regular.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/VisbyCF-Regular.eot?#iefix") format("embedded-opentype"),
    /* Legacy iOS */ url("./assets/fonts/VisbyCF-Regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/VisbyCF-Regular.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/VisbyCF-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "VisbyBold";
  src: url("./assets/fonts/VisbyCF-Bold.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/VisbyCF-Bold.eot?#iefix") format("embedded-opentype"),
    /* Legacy iOS */ url("./assets/fonts/VisbyCF-Bold.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/VisbyCF-Bold.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/VisbyCF-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "VisbyBold";
  user-select: none;
}
