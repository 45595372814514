@import "../../../assets/scss/variables";

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(black, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $z-index-modal;

  .container {
    display: flex;
    flex-direction: column;
    min-width: 50vw;
    max-height: 80vh;
    background: $color-white;
    border-radius: $border-radius-big;
    overflow: hidden;

    .header {
      display: flex;
      padding: 0 $padding-big;
      align-items: center;
      background: $color-primary-4;
      height: 50px;
      color: $color-white;

      .title {
        flex: 1 1 100%;
        font-size: $font-size-large;
      }

      .closeIcon {
        flex: 0 0 $icon-size-small;

        &:hover {
          cursor: pointer;
        }
      }
    }
    .content {
      display: flex;
      flex: 1 1 100%;
    }
  }

  @media (max-width: $window-size-mobile) {
    .container {
      min-width: 100%;
      min-height: 100%;
      border-radius: 0;
    }
  }
}
