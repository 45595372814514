@import "../../../assets/scss/variables";

.LocationContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $padding-medium;
  overflow-y: auto;
  max-height: 300px;

  .addButton {
    width: 100%;
  }

  .addedList {
    display: flex;
    flex-direction: column;

    .list {
      display: flex;
      flex-direction: column;

      .location {
        background: $color-white;
        color: $color-primary-4;
        margin-top: $margin-medium;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: $font-size-medium;
        font-weight: 600;
        padding: $padding-small $padding-medium;
        border-radius: $border-radius-small;
        border: 3px solid $color-primary-4;

        .remove {
          svg {
            width: 16px;
            height: 16px;
          }
          &:hover {
            cursor: pointer;
            color: $color-primary-3;
          }
        }
      }
    }
  }

  .searchList {
    display: flex;
    flex-direction: column;

    .buttons {
      display: flex;
      flex-wrap: nowrap;

      .button {
        margin-left: $margin-medium;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
