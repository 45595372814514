@import "../../../assets/scss/variables";

.FunctionTab {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-primary-5;
  padding: 10px;
  width: 20px;
  max-width: 20px;

  &__draggable {
    cursor: grab;
  }

  &:hover:not(&__draggable) {
    cursor: pointer;
  }
}
