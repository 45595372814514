@import "./mixins";

$color-primary-1: #b33600;
$color-primary-2: #cc3e00;
$color-primary-3: #e64500;
$color-primary-4: #ff4d00;
$color-primary-5: white;
$font-color: #363636;
$color-white: white;
$color-white-hover: #e5e5e5;

$msg-color-error: #d8000c;
$msg-color-warning: #fcd539;
$msg-color-info: #2fa0d8;

$msg-background-error: #f7ccce;
$msg-background-warning: #f9f1d0;
$msg-background-info: #d5e2e8;

$icon-size-small: 14px;
$icon-size-medium: 18px;
$icon-size-large: 24px;

$button-size: 32px;

$small-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.16);
$medium-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);

$margin-small: 5px;
$margin-medium: 10px;
$margin-big: 20px;

$padding-small: 5px;
$padding-medium: 10px;
$padding-big: 20px;

$font-size-small: 12px;
$font-size-normal: 14px;
$font-size-medium: 16px;
$font-size-large: 20px;
$font-size-xlarge: 24px;

$border-radius-small: 3px;
$border-radius-big: 15px;
$line-height: 24px;
$letter-spacing: 1.5px;

$z-index-modal: 1000;

$font-family-visby-regular: "VisbyRegular";
$font-family-visby-bold: "VisbyBold";
$font-family-source-sans-pro: "Source Sans Pro";

$window-size-mobile: 800px;
