@import "../../assets/scss/variables";

.NewStoryForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $padding-big;

  .values {
    flex: 1 1 100%;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: $margin-big;
  }
}
