@import "../../assets/scss/variables";

.ForgotPassword {
  display: flex;
  padding: 0 25%;
  justify-content: center;
  margin-top: $margin-big;

  .container {
    display: flex;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;

    > * {
      margin-bottom: $margin-medium;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
