.Playground {
    .box {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        border: 3px dotted black;
        background: white;
        padding: 10px;

        > * {
            margin-left: 10px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
