@import "../../../assets/scss/variables";

.CharacterList {
  display: flex;
  flex-direction: column;
  margin-top: $margin-medium;

  .character {
    display: flex;
    flex-direction: column;
    font-size: $font-size-large;
    margin-bottom: $margin-big;
    border-radius: $border-radius-small;
    overflow: hidden;

    .title {
      display: flex;
      padding: $padding-medium;
      background: $color-white;
      color: $color-primary-4;
      font-weight: 600;
      border: 3px solid $color-primary-4;
      justify-content: space-between;

      .name {
        font-family: $font-family-source-sans-pro;
        user-select: none;
      }

      &:hover {
        cursor: pointer;
      }

      .buttons {
        display: flex;
        align-items: center;

        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: $margin-medium;

          svg {
            width: 16px;
            height: 16px;
            color: $color-primary-4;

            &:hover {
              cursor: pointer;
              color: $color-primary-3;
            }
          }
        }
      }
    }

    .details {
      display: none;
      flex-direction: column;
      height: 0px;
      overflow: hidden;
      padding: $padding-medium $padding-medium 0 $padding-medium;
      border-width: 0 2px 2px 2px;
      border-color: $color-primary-4;
      border-style: solid;

      label,
      p {
        font-family: $font-family-source-sans-pro;
        font-size: $font-size-medium;
      }

      &__show {
        display: flex;
        height: auto;
        padding-top: $padding-medium;
      }
    }

    &__small {
      font-size: $font-size-medium;
      margin-bottom: $margin-medium;

      .title {
        padding: $padding-small;
      }
    }
  }
}
