@import "../../../assets/scss/variables";

.LoginScreen {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  align-items: flex-end;

  > * {
    margin-bottom: $margin-medium;
  }

  > button {
    margin-top: $margin-medium;
  }

  .captcha {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .forgotPassword {
    text-decoration: none;
    color: $color-primary-3;

    &:hover {
      color: $color-primary-1;
    }
  }
}
