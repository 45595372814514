@import "../../../assets/scss/variables";

.Navigation {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  background: $color-primary-4;

  .title {
    font-size: $font-size-xlarge;
    color: $color-white;
    font-family: $font-family-visby-bold;
  }

  .icons {
    display: flex;
    align-items: center;
    .icon {
      display: flex;
      color: $color-white;

      .actions {
        display: flex;
        align-items: center;
        z-index: 1;
        transform: translateX(30%);
        opacity: 0;
        transition: width ease 0.5s, opacity ease 0.5s, transform ease 0.5s;

        &__expanded {
          transform: translateX(0%);
          opacity: 1;
        }

        .label {
          font-family: $font-family-visby-bold;
          color: $color-white;
          padding: 0 $padding-medium;
          text-align: center;

          &:hover {
            cursor: pointer;
            color: $color-white-hover;
          }
        }
      }

      .innerIcon {
        display: flex;
        z-index: 2;
        background: $color-primary-4;

        svg {
          width: 24px;
          height: 24px;
        }

        &:hover {
          cursor: pointer;
          color: $color-white-hover;
        }
      }
    }
  }
}
