@import "../../../assets/scss/variables";

.RegisterScreen {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  align-items: flex-end;

  .captcha {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  > * {
    margin-bottom: $margin-small;
  }

  > button {
    margin-top: $margin-medium;
  }
}
