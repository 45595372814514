@import "../../../assets/scss/variables";

$toast-size: 300px;
$toast-height: 400px;

.Toast {
  display: flex;
  width: $toast-size;
  max-height: $toast-height;
  overflow-y: auto;
  border-radius: $border-radius-small;
  box-shadow: $medium-box-shadow;
  padding: $padding-medium 0;

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 30px;

    .icon {
      width: 15px;
      height: 15px;
    }
  }

  .content {
    flex: 1;
    font-size: $font-size-medium;
    padding: 0 $padding-small;

    .title {
      font-weight: bold;
    }

    .text {
    }
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 30px;
    height: 100%;
    cursor: pointer;

    .icon {
      width: 15px;
      height: 15px;
      color: rgba($font-color, 0.75);
    }

    &:hover {
      .icon {
        color: rgba($font-color, 0.5);
      }
    }

    &:active {
      .icon {
        color: $font-color;
      }
    }
  }

  &__warning {
    .iconContainer {
      color: $msg-color-warning;
    }

    background: $msg-background-warning;
  }

  &__error {
    .iconContainer {
      color: $msg-color-error;
    }
    background: $msg-background-error;
  }
  &__info {
    .iconContainer {
      color: $msg-color-info;
    }
    background: $msg-background-info;
  }
}
