@import "../../../assets/scss/variables";

.Scene {
  display: flex;
  flex-direction: column;
  align-items: center;

  .tabContainer {
    margin: $margin-big 0;
  }

  .addButton {
    height: 0px;
    padding: 0px;
    transition: height 0.2s ease, opacity 0.2s linear;
    opacity: 0;

    &__show {
      height: 32px;
      opacity: 1;
    }
  }

  @media (max-width: $window-size-mobile) {
    .addButton {
      &__top {
        opacity: 1;
        height: 32px;
      }

      &__bottom {
        height: 0;
        opacity: 0;
      }
    }

    &:last-of-type {
      .addButton {
        &__bottom {
          height: 32px;
          opacity: 1;
        }
      }
    }
  }
}
