@import "../../../assets/scss/variables";

.Popin {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  background: $color-primary-5;
  border-radius: $border-radius-big;
  overflow: hidden;
  box-shadow: $medium-box-shadow;

  .title {
    display: flex;
    flex: 1 1 100%;
    font-size: $font-size-medium;
    justify-content: center;
    padding: $padding-medium;
    font-family: $font-family-visby-bold;
    align-items: center;
    background: $color-primary-4;
    color: $color-primary-5;
  }

  .content {
    display: flex;
    padding: $padding-big;
  }
}
