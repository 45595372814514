@import "../../assets/scss/variables";

.LocationForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $padding-big;

  .values {
    display: flex;
    flex-direction: column;

    .textArea {
      @include textarea-custom-style;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: $margin-big;
  }
}
