@import "./assets/scss/variables";

.App {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .content {
    padding: $padding-big;
  }

  @media (max-width: $window-size-mobile) {
    .content {
      overflow: hidden;
      padding: $padding-small;
    }
  }
}
