@import "../../../assets/scss/_variables.scss";

.MessageDisplay {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 12px;
  right: 12px;

  .toast {
    display: flex;
    flex-direction: column;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
    min-width: 300px;
    max-width: 300px;
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;

    &__success {
      background: $msg-color-info;
      color: black;
    }
    &__warning {
      background: $msg-color-warning;
      color: black;
    }
    &__error {
      background: $msg-color-error;
      color: white;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.2rem;
      }

      .icon {
        &:hover {
          color: $color-white;
          cursor: pointer;
        }

        > svg {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }

    .text {
      font-size: 1rem;
    }
  }
}

@keyframes toast-in-right {
  0% {
    opacity: 0;
    margin-left: 100%;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}
