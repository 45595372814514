@import "../../../assets/scss/variables";

.Tab {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-primary-5;
  padding: 10px;
  flex: 0 0 20px;

  .icon {
    &__active {
      color: $color-primary-2;
    }
  }

  .label {
    transition: opacity 1s ease;
    opacity: 0;

    &__active {
      opacity: 1;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
