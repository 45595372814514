@mixin textarea-custom-style {
  width: 100%;
  border: none;
  padding: $padding-big;
  background: $color-primary-5;
  text-align: justify;
  resize: none;
  outline: none;
  box-shadow: none;
  overflow: hidden;
  color: $font-color;
  line-height: $line-height;
  font-size: $font-size-medium;
  border-bottom-left-radius: $border-radius-big;
  border-bottom-right-radius: $border-radius-big;
  font-family: $font-family-source-sans-pro;
  font-weight: 600;
}
