@import "../../../assets/scss/variables";

.Input {
  display: flex;
  flex: 1 1 100%;
  min-width: 100%;
  align-items: flex-end;
  position: relative;
  height: 50px;
  min-height: 50px;

  .label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: $font-size-normal;
    font-weight: bold;
  }

  .input {
    height: 30px;
    flex: 1 1 100%;
    outline: none;
    border: 2px solid transparent;
    font-family: $font-family-visby-bold;

    &:active,
    &:focus {
      border: 2px solid $color-primary-4;
    }
  }

  &__borderless {
    .input {
      background: rgba(grey, 0.1);
    }
  }
}
